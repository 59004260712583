<template>
  <v-data-table
    :headers="headers"
    :items="claims"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="900"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <div class="d-flex justify-space-between"> -->
            <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="close"
            >
              เพิ่มการเคลมสินค้า
            </v-btn>
            <!-- </div> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <!--                  <div class="alert alert-success" role="alert" >-->
                  <!--                    -->
                  <!--                  </div>-->
                  <!--                  <div class="alert alert-success" role="alert">-->
                  <!--                    -->
                  <!--                  </div>-->

                  <!--                  <div class="alert alert-warning" role="alert">-->
                  <!--                  </div>-->
                  <!--                  <div class="alert alert-danger" role="alert">-->
                  <!--                  </div>-->
                  <v-col
                    v-if="editedItem.type === 'C' && details && details.length > 0 && (editedItem && editedItem.PT_type === 'CC') || ((editedItem && editedItem.PT_type === 'T') && (editedItem && editedItem.additional_payment === 'N'))"
                    cols="12"
                    sm="12"
                    md="12"
                    class="success-badge"
                  >
                    <h4>
                      <i
                        class="fa fa-money"
                        aria-hidden="true"
                      ></i>
                      <span>
                        ยอดเครดิต {{ total || credit_expense }} <span>  (บาท)</span>
                      </span>
                    </h4>
                  </v-col>

                  <v-col
                    v-if="(editedItem && editedItem.type === 'C') && details && details.length > 0 && ((editedItem && editedItem.PT_type === 'T') && (editedItem && editedItem.additional_payment === 'Y')) || (editedItem && editedItem.PT_type === 'CP')"
                    class="success-badge"
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <h4>
                      <i
                        class="fa fa-money"
                        aria-hidden="true"
                      ></i>
                      <span>
                        ยอดเครดิต {{ total || credit_expense }}<span>  (ชิ้น)</span>
                      </span>
                    </h4>
                  </v-col>

                  <v-col
                    v-if="editedItem.type === 'O' "
                    class="warning-badge"
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <h4>
                      <i
                        class="fa fa-file"
                        aria-hidden="true"
                      ></i>
                      <span> เลขที่บิลเดิม {{ editedItem.order_inv }}</span>
                    </h4>
                  </v-col>

                  <v-col
                    v-if="details && details.length < 1 && orders && orders.length > 0"
                    class="danger-badge"
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <span
                      id="border-error"
                      style="color: white"
                    >* กรุณาเเลือกเลขใบสั่งซื้อ</span>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-card>
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                          >
                            <v-select
                              v-model="editedItem.order_id"
                              item-text="order_inv"
                              item-value="order_id"
                              label="เลขใบสั่งซื้อ"
                              :disabled="editedItem.claim_id != ''"
                              :items="orders"
                              @change="selectOrder"
                            >
                            </v-select>

                            <small
                              v-for="(err,index) in v$.editedItem.order_id.$silentErrors"
                              v-if="v$.editedItem.order_id.$error"
                              class="validate-err"
                            >{{ index > 0 ? ', ' : '' }}
                              {{ err.$message }}</small>
                          </v-col>

                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                          >
                            <v-radio-group
                              v-model="editedItem.type"
                              :disabled="editedItem.claim_status == 'Y' || ($store.state.user.member_id && editedIndex >= 0)"
                              label="ความต้องการเคลม"
                            >
                              <v-radio
                                value="C"
                                :disabled="editedItem.claim_status === 'Y'"
                                label="คืนเครดิต "
                              ></v-radio>

                              <!--                              <span v-if="(order.PT_type === 'T' && order.additional_payment === 'Y') || order.PT_type === 'CP'"> (สินค้า)</span>-->
                              <!--                              <span v-if="order.PT_type === 'CC' || (order.PT_type === 'T' && order.additional_payment === 'N')">  (เงินออนไลน์)</span>-->

                              <v-radio
                                value="O"
                                :disabled="editedItem.claim_status === 'Y'"
                                label="เปิดบิลใหม่"
                              ></v-radio>
                            </v-radio-group>
                            สถานะ
                            <span
                              v-if="editedItem.claim_status === 'Y'"
                              class="badge badge-success"
                              style="font-size: 13px;"
                            > อนุมัติเรียบร้อย
                            </span>
                            <span
                              v-if="editedItem.claim_status === 'W'"
                              class="badge badge-warning"
                              style="font-size: 13px;"
                            >
                              รอการตรวจสอบการอนุมัติ
                            </span>
                            <span
                              v-if="editedItem.claim_status === 'C'"
                              class="badge badge-danger"
                              style="font-size: 13px;"
                            >
                              ไม่อนุมัติ
                            </span>
                            <span
                              v-if="editedItem.claim_status !== 'C' && editedItem.claim_status !== 'C' && editedItem.claim_status !== 'C'"
                            >
                              สร้างการเคลม
                            </span>
                          </v-col>

                          <v-col
                            v-if="(editedItem.type === 'C' && editedItem.order_id != '') && !$store.state.user.member_id"
                            cols="12"
                            sm="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="editedItem.credit_expense"
                              :disabled="editedItem.claim_status === 'Y'"
                              min="0"
                              type="number"
                              label="กำหนดคืนเครดิต (เงินออนไลน์) *"
                            ></v-text-field>

                            <!--                    <span  v-if="editedItem.type === 'C' && editedItem.length > 0 && order.PT_type === 'CC' || (order.PT_type === 'T' && order.additional_payment === 'N')">  (บาท)</span>-->
                            <!--                    <span v-if="editedItem.type === 'C' && editedItem.length > 0 && (order.PT_type === 'T' && order.additional_payment === 'Y') || order.PT_type === 'CP'">  (ชิ้น)</span>-->
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col
                    v-if="editedItem.claim_id && editedItem.claim_status != 'Y'"
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-card>
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                          >
                            <h4>เครื่องมือ</h4>
                          </v-col>

                          <v-col
                            v-if="!$store.state.user.member_id"
                            cols="6"
                            sm="6"
                            md="6"
                          >
                            <v-btn
                              style="width: 100%"
                              color="primary"
                              @click="approveClaim"
                            >
                              อนุมัติการเคลม
                            </v-btn>
                          </v-col>

                          <v-col
                            cols="6"
                            sm="6"
                            md="6"
                          >
                            <span v-if="editedItem.claim_status !== 'C'">
                              <v-btn
                                v-if="!$store.state.user.member_id"
                                class="danger-badge"
                                style="width: 100%"
                                @click="updateStatus(editedItem.claim_id,'C')"
                              >
                                ยกเลิกการเคลม
                              </v-btn>
                              <v-btn
                                v-if="$store.state.user.member_id"
                                class="danger-badge"
                                style="width: 100%"
                                @click="updateCancel"
                              >
                                ยกเลิกการเคลม
                              </v-btn>

                            </span>
                            <span v-if="editedItem.claim_status === 'C'">
                              <v-btn
                                style="width: 100%"
                                @click="updateStatus(editedItem.claim_id,'W')"
                              >
                                เคลมอีกครั้ง
                              </v-btn>
                            </span>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row v-if="details && details.length > 0">
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-card>
                      <v-card-text style="overflow: auto">
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                          >
                            <table class="table">
                              <thead>
                                <tr>
                                  <th class="text-center">
                                    #
                                  </th>
                                  <th>ชื่อสินค้า</th>
                                  <th>กดเพื่อแนบไฟล์เสียหาย</th>
                                  <th>สินค้าในสต็อก</th>
                                  <th>จำนวนชิ้น</th>
                                  <th>สถานะ</th>
                                  <th v-if="!$store.state.user.member_id">
                                    เครื่องมือ
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item,index) in details">
                                  <td class="text-center">
                                    {{ index + 1 }}
                                  <!--                                  <v-checkbox-->
                                  <!--                                    dense-->
                                  <!--                                    hide-details-->
                                  <!--                                    :value="item.id"-->
                                  <!--                                  ></v-checkbox>-->
                                  </td>
                                  <td>
                                    <ul>
                                      <span
                                        style="font-weight: bolder;color: #27AE60"
                                      >ชื่อลาย{{
                                        item.pattern.pattern_name
                                      }}</span>
                                      <li>
                                        <span>สินค้า: {{
                                          item.product ? item.product.product_name : '-'
                                        }} </span>
                                      </li>
                                      <li>
                                        <span>รหัส: {{
                                          item.product ? item.product.product_code : '-'
                                        }}</span>
                                      </li>
                                      <li>
                                        <span>ยี่ห้อ: {{
                                          item.product && item.product.brand ? item.product.brand.phone_brand_name : '-'
                                        }}</span>
                                      </li>
                                      <li>
                                        <span>รุ่น: {{
                                          item.product && item.product.phone_model ? item.product.phone_model.phone_model_name : '-'
                                        }}</span>
                                      </li>
                                      <li>
                                        <span style="color: red">ราคา: {{
                                          item ? item.product_price : '-'
                                        }}</span>
                                      </li>
                                    </ul>
                                  </td>
                                  <td>
                                    <!--                                  <button type="button"-->
                                    <!--                                          class="btn btn-warning" data-toggle="modal"-->
                                    <!--                                          v-if="!item.claim_detail_id"-->
                                    <!--                                          data-target="#UploadFile">-->

                                    <!--                                    &lt;!&ndash;                            @click="modelEditFile(index,null)"&ndash;&gt;-->
                                    <!--                                    <i class="fa fa-file" aria-hidden="true"></i> แนบไฟล์-->
                                    <!--                                  </button>-->

                                    <v-btn
                                      v-if="!item.claim_detail_id && !( item.file && item.file.name)"
                                      small
                                      @click="uploadClaimFile(index,item)"
                                    >
                                      แนบไฟล์
                                    </v-btn>

                                    <a
                                      v-if="( item.file && item.file.name)"
                                      @click="uploadClaimFile(index,item)"
                                    >{{ item.file.name }}</a>
                                    <!--                                  <v-btn-->
                                    <!--                                    title="กดเพื่อดูไฟล์แบบ"-->
                                    <!--                                    @click="uploadClaimFile(item)"-->
                                    <!--                                    v-if="item.claim_img">-->
                                    <!--                                    ไฟล์เสียหายเดิม-->
                                    <!--                                    <i class="fa fa-cloud-download" aria-hidden="true"></i>-->
                                    <!--                                  </v-btn>-->
                                    <!--                                  <a v-if="item.claim_img" :href="item.claim_img"-->
                                    <!--                                     target="_blank"-->
                                    <!--                                     title="กดเพื่อดูไฟล์แบบ">ไฟล์เสียหายเดิม-->
                                    <!--                                  </a>-->
                                    <!-- @if($data->claim_status != 'Y')-->
                                    <a
                                      v-if="item.claim_detail_id"
                                      @click="uploadClaimFile(index,item)"
                                    >
                                      รายละเอียดการเคลม
                                      <i
                                        class="fa fa-cloud-upload"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </td>
                                  <td>
                                    {{
                                      item.product && item.product.product_balance ? item.product.product_balance : item.product_amount
                                    }} ชิ้น
                                  </td>
                                  <td>
                                    <div v-if="editedItem.claim_status !== 'Y'">
                                      <div v-if="item.detail_amount && item.product_balance > 0">
                                        <!--                              <number-input v-model="item.detail_amount" :min='1'-->
                                        <!--                                            :max='parseInt(item.amount)' inline center-->
                                        <!--                                            controls></number-input>-->
                                        <v-text-field
                                          v-model="item.detail_amount"
                                          type="number"
                                          min="1"
                                          :max="parseInt(item.amount)"
                                          inline
                                          center
                                        ></v-text-field>
                                      </div>
                                      <div
                                        v-if="!item.detail_amount && item.product && item.product.product_balance > 0"
                                      >
                                        <v-text-field
                                          v-model="item.amount"
                                          type="number"
                                          min="1"
                                          :max="parseInt(item.amount)"
                                        ></v-text-field>
                                      <!--                              <number-input v-model="item.amount" :min='1'-->
                                      <!--                                            :max='parseInt(item.amount)'-->
                                      <!--                                            inline center controls></number-input>-->
                                      </div>
                                      <!--                                    <p v-if="!item.product_balance"> จำนวนไม่เพียงพอ </p>-->
                                      <div
                                        v-if="!item.product_balance && item.product && item.product_amount"
                                      >
                                        <v-text-field
                                          v-model="item.amount"
                                          type="number"
                                          min="1"
                                          :max="parseInt(item.product_amount)"
                                        ></v-text-field>
                                      <!--                              <number-input v-model="item.amount" :min='1'-->
                                      <!--                                            :max='parseInt(item.product_amount)'-->
                                      <!--                                            inline center controls></number-input>-->
                                      </div>
                                    </div>
                                    <div v-if="editedItem.claim_status === 'Y'">
                                      {{ item.amount }}
                                    </div>
                                  </td>
                                  <td>
                                    <span
                                      v-if="(item.approve_status === 'Y')"
                                      class="badge badge-success"
                                      style="font-size: 13px;"
                                    > อนุมัติเรียบร้อย</span>
                                    <span
                                      v-if="item.approve_status === 'W' "
                                      class="badge badge-warning"
                                      style="font-size: 13px;"
                                    > รอการตรวจสอบ</span>
                                    <span
                                      v-if="item.approve_status === 'C' "
                                      class="badge badge-danger"
                                      style="font-size: 13px;"
                                    > ไม่อนุมัติ</span>
                                    <span
                                      v-if="item.approve_status === 'W' && item.product_balance < item.amount && type === 'O'"
                                      style="font-size: 13px;color:red;"
                                    > <br>หมายเหตุ: จำนวนไม่เพียงพอ</span>
                                    <span
                                      v-if="!item.approve_status && editedItem.claim_id"
                                      style="font-size: 13px;"
                                    > - </span>
                                    <span
                                      v-if="!editedItem.claim_id && details.length > 1 && !editedItem.detail_amount"
                                      style="color: red;cursor: pointer"
                                      class="btn btn-danger"
                                      @click="removeRow(index)"
                                    >ลบ</span>
                                  </td>
                                  <td v-if="!$store.state.user.member_id">
                                    <span
                                      v-if="!editedItem.claim_id && details.length > 1 && !editedItem.detail_amount"
                                      style="color: red;cursor: pointer"
                                      class="btn btn-danger"
                                      @click="removeRow(index)"
                                    >ลบ</span>
                                    <div v-if="(editedItem.claim_status != 'Y')">
                                      <div v-if="editedItem.claim_id">
                                        <v-btn
                                          color="primary"
                                          :disabled="(item.approve_status === 'Y' || (item.product_balance < item.amount && editedItem.type === 'O') && item.product.product_balance > 0) && (item.product && item.product.product_type_id != null)"
                                          title="คืนเครดิตพร้อมเงินค่าส่ง กรณีอนุมัติการเคลมสินค้า"
                                          @click="updateClaimDetailStatus(item.claim_detail_id,+'1',item.amount,index)"
                                        >
                                          อนุมัติ
                                        </v-btn>

                                        <!--                                      <button class="btn btn-primary btn-sm"-->
                                        <!--                                              :disabled="(item.approve_status === 'Y' || (item.product_balance < item.amount && type === 'O') && item.product_balance > 0) && item.product_type_id"-->
                                        <!--                                              title="คืนเครดิตพร้อมเงินค่าส่ง กรณีอนุมัติการเคลมสินค้า"-->
                                        <!--                                      >-->
                                        <!--                                @click="updateStatus(item.claim_detail_id,+'1',item.amount)"-->

                                        <!--                                      </button>-->
                                        <v-btn
                                          :disabled="item.approve_status === 'C' && (item.product && item.product.product_type_id != null)"
                                          @click="updateClaimDetailStatus(item.claim_detail_id,+'2',item.amount,index)"
                                        >
                                          ไม่อนุมัติ
                                        </v-btn>
                                      </div>
                                      <i
                                        v-if="editedItem.length > 1"
                                        style="color: #ff4d4d"
                                        class="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    <!--                            @click="removeRow(index)"-->
                                    </div>
                                    <div v-if="editedItem.claim_status == 'Y'">
                                      -
                                    </div>
                                    <p
                                      v-if="!item.product || !item.product.product_type_id"
                                      style="color: red"
                                    >
                                      ไม่พบประเภทสินค้ากรุณาเปลี่ยนแปลงข้อมูลประเภทสินค้า
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <!--              v-if="(editedItem.order_id && !$store.state.user.member_id ) || editedIndex < 0"-->
              <v-btn
                v-if="(editedItem.order_id ) && editedIndex < 0"
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogCancel"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-textarea
                      v-model="editedItem.C_order_note_cancel"
                      label="กรุณากรอกเหตุผล *"
                      placeholder="กรุณากรอกเหตุผล การยกเลิกการจองการสั่งซื้อ"
                    ></v-textarea>
                    <!--                <small-->
                    <!--                  class="validate-err"-->
                    <!--                  v-for="(err,index) in v$.editedItem.name.$silentErrors"-->
                    <!--                  v-if="v$.editedItem.name.$error">{{ index > 0 ? ', ' : '' }}-->
                    <!--                  {{ err.$message }}</small>-->
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="close"
              >
                ยกเลิก
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="updateCancelConfirm"
              >
                ยืนยัน
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogUpload"
          max-width="700"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">แนบไฟล์</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-img
                      v-if="uploadClaim.claim_img"
                      :src="uploadClaim.claim_img"
                    ></v-img>
                    <v-file-input
                      v-model="uploadClaim.file"
                      :disabled="editedItem.claim_status === 'Y'"
                      label="เลือกไฟล์เสียหาย"
                      @change="uploadFile($event)"
                    ></v-file-input>

                    <v-textarea
                      v-model="uploadClaim.claim_detail"
                      :disabled="editedItem.claim_status === 'Y'"
                      label="รายละเอียด"
                      placeholder="รายละเอียด"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="closeUploadClaim"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="requestClaim()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
    </template>
    <template v-slot:item.ref="{ item }">
      <a
        :id="item.claim_id"
        @click="editItem(item)"
      >
        {{ item.claim_no }}
      </a>
    </template>

    <template v-slot:item.type="{ item }">
      <span v-if="item.type === 'C'">คืนเครดิต</span>
      <span v-if="item.type === 'O'">เปิดบิลใหม่</span>
      <!--      </v-checkbox>-->
    </template>
    <template v-slot:item.claim_status="{ item }">
      <!--      <v-checkbox >-->
      <!--        item.package_status-->
      <!--        v-model="item.package_status"-->

      <span
        v-if="item.claim_status === 'Y'"
        class="success-badge"
      >อนุมัติเรียบร้อย</span>
      <span
        v-if="item.claim_status === 'W'"
        class="warning-badge"
      >รอตรวจสอบ</span>
      <span
        v-if="item.claim_status === 'C'"
        class="danger-badge"
      >ยกเลิก</span>
      <!--      </v-checkbox>-->
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn
        class="search-box secondary mr-2"
        @click="editItem(item)"
      >
        ดูรายละเอียด
      </v-btn>
      <v-btn
        v-if="(item.claim_status != 'Y' && item.claim_status != 'C') && !$store.state.user.member_id"
        class="danger-badge"
        @click="updateStatus(item.claim_id,'C')"
      >
        ยกเลิก
      </v-btn>

      <v-btn
        v-if="(item.claim_status != 'Y' && item.claim_status == 'C') && !$store.state.user.member_id"
        class="success-badge"
        @click="updateStatus(item.claim_id,'W')"
      >
        ส่งเคลม
      </v-btn>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'

// import config from '../../../config'
import Swal from 'sweetalert2'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import instance from '../../../axios.service'
import instance_claim from '../../../services/claim'
import instance_order from '../../../services/order'
import Card from '@/views/cards/Card'

export default {
  components: { Card },
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    order_id: '',
    serverOptions: {
      page: 1,
      itemsPerPage: 10,
    },
    order: null,
    fileUpload: null,
    total: 0,
    claims: [],
    details: [],
    orders: [],
    dialog: false,
    dialogDelete: false,
    dialogUpload: false,
    dialogCancel: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index',
        sortable: false,
      },
      { text: 'เลขที่ใบเคลม', value: 'ref', sortable: false },
      { text: 'ชื่อลูกค้า', value: 'member.member_name', sortable: false },
      { text: 'วันที่แจ้งเคลม', value: 'claim_date_time' },
      { text: 'จำนวนส่งเคลม', value: 'claim_amt' },
      { text: 'จำนวนอนุมัติ', value: 'claim_approve_amt' },
      { text: 'สถานะ', value: 'claim_status' },
      { text: 'ตรวจสอบ', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedClaimIndex: -1,
    editedItem: {
      claim_id: '',
      order_id: '',
      type: 'C',
      credit_type: '0',
      claim_status: 'N',
      claim_amt: 0,
      credit_expense: 0,
      claim_approve_amt: 0,
    },
    defaultItem: {
      claim_id: '',
      order_id: '',
      type: 'C',
      credit_type: '0',
      claim_status: 'N',
      claim_amt: 0,
      credit_expense: 0,
      claim_approve_amt: 0,
    },
    uploadClaimId: '',
    uploadClaim: {
      file: null,
      claim_detail: '',
    },
    defaultUploadClaim: {
      file: null,
      claim_detail: '',
    },
  }),
  validations() {
    return {
      editedItem: {
        order_id: { required },
      },
    }
  },
  computed: {
    formTitle() {
      return this.$store.state.user.member_id ? 'รายละเอียดการเคลมสินค้า' : (this.editedIndex === -1 ? 'เพิ่ม การเคลมสินค้า' : 'แก้ไข การเคลมสินค้า')
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getClaim()
      },
    },
    search: {
      handler() {
        this.getClaim()
      },
    },

    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
    this.headers.splice(6, -1, { text: 'ความต้องการเคลม', value: 'type' })
    if (this.$store.state.user.member_id) {
      this.headers.splice(2, 1)
    }
  },
  mounted() {
    // this.getUser()
    // this.headers.slice(1,-2,)
    this.getClaim()
    this.getOrder()
  },

  methods: {
    uploadFile(file) {
      this.uploadClaim.file = file
      this.fileUpload = file
    },
    uploadClaimFile(index, item) {
      this.editedClaimIndex = index
      this.uploadClaimId = item.claim_detail_id ? item.claim_detail_id : ''

      // this.closeUploadClaim()
      this.dialogUpload = true

      // var url = URL.createObjectURL(item.file);
      // console.log(url,5555)
      this.uploadClaim = item

      // this.index = index
      // this.claim_detail_id = claim_detail_id
      // if (claim_detail_id) {
      //   this.claim_detail = this.data[index].claim_detail
      // }
    },
    editItem(item) {
      this.$store.state.isLoading = true
      this.editedItem = { ...item }
      this.editedIndex = this.claims.indexOf(item)

      instance_claim.getClaimDetail(this.editedItem.claim_id).then(res => {
        this.dialog = true
        this.details = res.data.claim_detail
        this.order = res.data.order_inv_list_old
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },

    deleteItem(item) {
      this.editedIndex = this.claims.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      // instance_claim.dele(this.editedItem).then(res => {

      // this.points.splice(this.editedIndex, 1)
      // }).catch(err => {
      //
      // })
      this.closeDelete()
    },
    updateCancel() {
      this.dialogCancel = true
    },
    updateCancelConfirm() {
      let check = ''
      Swal.fire({
        title: 'คุณแน่ใจ',
        text: 'คุณแน่ใจต้องการยกเลิกการสั่งซื้อใช่หรือไม่!',
        type: 'warning',
        showCancelButton: false,
        confirmButtonClass: 'btn-danger',
        confirmButtonText: 'ยกเลิก',
        closeOnConfirm: false,

      }).then(result => { // <--
        if (!result) {
          check = false
        } else {
          check = true
          if (check) {
            this.$store.state.isLoading = true
            const data = {
              order_id: this.editedItem.order_id,
              product_amount_comment: this.editedItem.C_order_note_cancel,
              status: 'Y',
            }
            instance_order.orderCancel(data).then(res => {
              this.$store.state.isLoading = false
              Swal.fire({
                text: 'ยกเลิกการสั่งซื้อสำเร็จ',
                timer: 3000,
                icon: 'success',
                showConfirmButton: false,
                button: false,
              })
            }).catch(err => {
              this.$store.state.isLoading = false
              this.$store.state.isLoading = false
              Swal.fire({
                text: err.message,
                timer: 3000,
                icon: 'error',
                showConfirmButton: false,
                button: false,
              })
            })

            //   axios.post('<?php echo e(url('api/user/updateOrder')); ?>' + '/' + this.order.order_id,
            //     data, config, {}
            // )
            // .then(function (response) {
            //     this.closeDelete()
            //   })
            //
            //     .catch(function (error) {
            //     });
          }
        }
      })
    },
    updateStatus(id, status) {
      let text = ''
      if (status === 'C') {
        text = 'คุณต้องการยกเลิกการเคลมใช่หรือไม่'
      } else if (status === 'W') {
        text = 'คุณต้องการส่งการเคลมอีกครั้งใช่หรือไม่'
      }
      const c = confirm(text)
      if (c) {
        this.$store.state.isLoading = true
        const data = {
          id,
          claim: status,
          status,
          credit_type: '',
        }

        if (status === 'C') {
          instance_claim.cancelClaim(data).then(res => {
            Swal.fire({
              icon: 'success',
              text: res.data.message,
              timer: 1500,
            })
            this.getClaim()
            this.close()
          }).catch(err => {
            this.$store.state.isLoading = false
            Swal.fire({
              icon: 'success',
              title: 'เกิดข้อผิดพลาด !!',
              text: err.message,
            })
          })
        } else if (status === 'W') {
          instance_claim.approveClaim(data).then(res => {
            Swal.fire({
              icon: 'success',
              text: res.data.message,
              timer: 1500,
            })
            this.getClaim()
            this.close()
          }).catch(err => {
            this.$store.state.isLoading = false
            Swal.fire({
              icon: 'success',
              title: 'เกิดข้อผิดพลาด !!',
              text: err.message,
            })
          })
        }
      }
    },
    removeRow(index) {
      this.details.splice(index, 1)
    },
    updateClaimDetailStatus(id, type, amount, index) {
      this.$store.state.isLoading = true

      // +1 +2
      // +1 +2
      const data = {
        id,
        type,
        amount,
      }

      // if (type == '+1'){
      //   this.details[index].approve_status = 'Y'
      // }else{
      //   this.details[index].approve_status = 'C'
      // }

      instance_claim.updateClaimDetail(data).then(res => {
        instance_claim.getClaimDetail(this.editedItem.claim_id).then(res => {
          this.details = res.data.claim_detail
          this.order = res.data.order_inv_list_old
          this.$store.state.isLoading = false
        }).catch(err => {
          this.$store.state.isLoading = false
        })
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    close() {
      this.v$.$reset()
      this.dialog = false
      this.details = []
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },
    closeUploadClaim() {
      this.uploadClaimId = ''
      this.dialogUpload = false
      this.$nextTick(() => {
        this.fileUpload = null
        this.uploadClaim = { ...this.defaultUploadClaim }
      })
    },
    requestClaim() {
      if (this.uploadClaimId) {
        this.$store.state.isLoading = true
        instance_claim.uploadClaim(this.uploadClaimId, this.uploadClaim).then(res => {
          this.$store.state.isLoading = false
          this.dialogUpload = false
          if (res.data.success) {
            Swal.fire({
              icon: 'success',
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            })
            instance_claim.getClaimDetail(this.editedItem.claim_id).then(res => {
              this.details = res.data.claim_detail

              // this.order = res.data.order_inv_list_old
            }).catch(err => {
              this.$store.state.isLoading = false
              this.closeUploadClaim()
              Swal.fire({
                icon: 'error',
                title: err.message,
                showConfirmButton: false,
                timer: 1500,
              })
            })
          } else {
            this.$store.state.isLoading = false
            Swal.fire({
              icon: 'error',
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            })
          }

          // this.$router.push({name: 'pages-claim'});
        }).catch(err => {
          this.$store.state.isLoading = false

          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          })
        })
      } else {
        this.details[this.editedClaimIndex].file = this.fileUpload
        this.closeUploadClaim()
        Swal.fire({
          icon: 'success',
          title: 'อัพโหลดไฟล์สำเร็จ',
          showConfirmButton: false,
          timer: 1500,
        })
        this.claim_detail = ''
        this.editedClaimIndex = -1
        this.uploadClaimId = ''
      }
    },
    approveClaim() {
      let credit_type = ''
      const c = confirm('อนุมัติการเคลม ใช่หรือไม่ ?')
      if (c) {
        this.$store.state.isLoading = true
        if (this.editedItem.type === 'C') {
          if ((this.order.PT_type === 'T' && this.order.additional_payment === 'Y') || this.order.PT_type === 'CP') {
            credit_type = 'CC'
          } else if (this.order.PT_type === 'CC' || (this.order.PT_type === 'T' && this.order.additional_payment === 'N')) {
            credit_type = 'CP'
          }
        }
        const data = {
          id: this.editedItem.claim_id,
          status: 'Y',
          credit_type,
          type: this.editedItem.type,
          details: this.details,
        }

        instance_claim.approveClaim(data).then(res => {
          if (res.data.success) {
            Swal.fire({
              icon: 'success',
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            })
            this.getClaim()
            this.close()

            this.$store.state.isLoading = false
          } else {
            this.$store.state.isLoading = false

            Swal.fire({
              icon: 'error',
              title: 'เกิดข้อผิดพลาด !!',
              text: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            })
          }
        }).catch(err => {
          this.$store.state.isLoading = false
        })
      }
    },
    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      let error = true
      for (let i = 0; i < this.details.length; i++) {
        if (this.details[i].amount > 0 && this.details[i].file) {
          error = false
          break
        }
      }

      if (error) {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: 'กรุณาแนบไฟล์ และระบุจำนวน อย่างน้อย 1 รายการ',
        })

        return true
      }

      this.$store.state.isLoading = true

      const data = {
        claim_id: this.editedItem.claim_id,
        data: this.editedItem,
        details: this.details,
      }
      if (this.editedIndex > -1) {
        instance_claim.updateClaim(data)
          .then(res => {
            Swal.fire({
              icon: 'success',
              text: res.data.message,
              timer: 1500,
            })
            this.getClaim()
            this.close()
          }).catch(err => {
            this.$store.state.isLoading = false
            Swal.fire({
              icon: 'error',
              title: 'เกิดข้อผิดพลาด !!',
              text: err.message,
              timer: 1500,
            })
          })
      } else {
        instance_claim.storeClaim(data)
          .then(res => {
            Swal.fire({
              icon: 'success',
              text: res.data.message,
              timer: 1500,
            })
            this.getClaim()
            this.close()
          }).catch(err => {
            this.$store.state.isLoading = false

            Swal.fire({
              icon: 'error',
              title: 'เกิดข้อผิดพลาด !!',
              text: err.message,
              timer: 1500,
            })
          })
      }
    },
    getClaim() {
      this.$store.state.isLoading = true
      const data = {
        search: this.$route.query.search ? this.$route.query.search : this.search,
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
        order_id: this.order_id,
        date: this.$route.query.date ? this.$route.query.date : '',
        status: this.$route.query.status ? this.$route.query.status : '',
        sortBy: this.sortBy ?? '',
        sortDesc: this.sortDesc ?? 'DESC',
      }

      instance_claim.getClaim(data).then(res => {
        this.claims = res.data.data.data
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false

        if (this.$route.query.search && this.$route.query.claim_id) {
          document.getElementById(this.$route.query.claim_id).click()
        }
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    getOrder() {
      this.$store.state.isLoading = true
      const data = {
        search: '',
        date: '',
        status: '',
        sortBy: 'Desc',
      }

      instance_order.getPendingOrder(data).then(res => {
        this.orders = res.data.data
        this.$store.state.isLoading = false
      }).catch(err => {

      })
    },
    selectOrder() {
      this.$store.state.isLoading = true
      instance_order.getOrderDetail(this.editedItem.order_id).then(res => {
        this.details = res.data.data
        this.order = res.data.order
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
