import api from '../axios.service'

export default {
  getClaim(data) {
    return api.get(`claim/get_claim?search=${data.search}&date=${data.date}&status=${data.status}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  showClaim(id) {
    return api.get(`claim/getDetail/${id}`)
  },
  // eslint-disable-next-line camelcase
  show(PT_BankID) {
    // eslint-disable-next-line camelcase

    return api.get(`/apiBankShow?BankID=${PT_BankID}`)
  },

  getClaimDetail(claim_id) {
    return api.get(`claim/get_claim/${claim_id}`)
  },

  storeClaim(data) {
    let getFormData = data.data
    let details = data.details;
    let params = new FormData();

    if(details) {
      for(var i =0 ;i< details.length;i++) {
        params.append('order_details[]', JSON.stringify(details[i]))

        params.append('file_'+i, details[i]['file']);

      }
    }

    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        var index =0;
        params.append(key, getFormData[key]);


      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post('claim/store',params,config)
  },
  updateClaim(data) {
    let getFormData = data.data
    let details = data.details;
    let params = new FormData();

    if(details) {
      for(var i =0 ;i< details.length;i++) {
        params.append('order_details[]', JSON.stringify(details[i]))

        params.append('file_'+i, details[i]['file']);
      }
    }
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        var index =0;
        params.append(key, getFormData[key]);
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`claim/update/${data.claim_id}`,params,config)
  },
  updateClaimDetail(data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`claim/update_claim_detail/${data.id}`,params,config)
  },

  uploadClaim(id,data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`claim/update_file_claim/${id}`,params,config)
  },

  approveClaim(data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        if (key != 'details') {
          params.append(key, getFormData[key]);
        }else{
          for (var i=0; i < getFormData[key].length;i++) {
            // for (var detail in getFormData[key][i]) {
            //   console.log(getFormData[key][i],111,detail)
              params.append(`details[${i}]['amount']`, getFormData[key][i].amount);
              params.append(`details[${i}]['product_id']`, getFormData[key][i].product_id);
              params.append(`details[${i}]['claim_detail_id']`, getFormData[key][i].claim_detail_id);
              params.append(`details[${i}]['approve_status']`, getFormData[key][i].approve_status);
            // }
          }
        }
      }
    }
    return api.post(`claim/claim_update_status/${data.id}`,params)

      // /admin/claim_update_status/{{$data->claim_id}}
  },

  cancelClaim(data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    return api.post(`claim/claim_update_status/${data.id}`,params)
  },
}
var claim_canceled = '';
// if (id == null) {
//   return '<span>-</span>'
// } else {
//
//   if (row.claim_status != 'Y') {
//     if (row.claim_status === 'C') {
//       claim_canceled = '<a onclick="return confirm(\'คุณต้องการส่งการเคลมอีกครั้งใช่หรือไม่?\')" href="claim_update_status/' + id + '/?status=W" class="btn btn-primary btn-sm" onclick=""><i class="icon icon-danger"></i>&nbsp;ส่งเคลม</a>'
//
//     } else {
//       claim_canceled = '<a onclick="return confirm(\'คุณต้องการยกเลิกการเคลมใช่หรือไม่?\')" href="claim_update_status/' + id + '/?status=C" class="btn btn-danger btn-sm" onclick=""><i class="icon icon-danger"></i>&nbsp;ยกเลิก</a>'
//
//     }
//   }
//
//
//   return '<a href="claim/' + id + '" class="btn btn-primary btn-sm"  onclick=""><i class="icon icon-danger"></i>&nbsp;ตรวจสอบ</a>&nbsp;' +
//     claim_canceled;
//
//
// }
